import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import office from "../images/office.jpg"

const Organisatieadvies = () => {
  const data = useStaticQuery(graphql`
    query {
      werkvloer1: file(relativePath: { eq: "werkvloer1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      werkvloerrect: file(relativePath: { eq: "werkvloerrect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Breinkracht organisatieadvies"
        description="Breinkracht organisatieadvies op basis van de Zipperr® Methode, een no nonsense programma voor duurzame inzetbaarheid en vitaliteit in organisaties."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${office})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Breinkracht organisatieadvies op basis van de Zipperr® Methode
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Wat is zipperr?</p>
        </div>
        <p>
          De Zipperr®Methode is een no nonsense programma voor duurzame
          inzetbaarheid en vitaliteit in organisaties. Zipperen betekent het
          openen en weer dichtritsen van problemen waar je tegenaan loopt op
          organisatorisch en persoonlijk vlak. Vroeg of laat krijgen we te maken
          met tegenslagen in het leven. We krijgen te maken met stress,
          conflicten, slapeloosheid, ziekte en onbalans. Door zelfkennis en
          bewustwording van de invloeden van jouw omgeving, kun jij ook
          effectiever worden.
        </p>
      </div>

      <div className={"containerPad withFlex"}>
        <div className={"half block figurealt"}>
          <div className={"imageStackedBox"}>
            <Img
              fluid={data.werkvloerrect.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipperr Case"
              style={{ maxHeight: "45%" }}
              className={"stacked1 further3"}
            />
            <Img
              fluid={data.werkvloer1.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipperr Case"
              style={{
                left: '35%',
              }}
              className={"stacked2 further2"}
            />
          </div>
        </div>
        <div className={"half block"}>
          <h2>
            Niet alleen voor organisatieontwikkeling of business consult, ook
            voor persoonlijke ontwikkeling en teambuilding is de Zipperr®
            Methode effectief.{" "}
          </h2>
          <p>
            Wanneer je op zoek bent naar meer balans, focus en energie in je
            leven en op de werkvloer, wil je graag handvaten om dit te bereiken.
            Je kunt je mentale weerbaarheid versterken door je gedachten sterker
            te maken, waarmee je ook fysiek sterker wordt. Je gedachten bepalen
            je gevoelens. Met mentale weerbaarheid en het veranderen van
            gedragspatronen heb je meer controle en balans in je werk en in je
            leven.
          </p>
          <p>
            De Zipperr®Methode helpt daarbij, dankzij een unieke combinatie van
            verschillende mentale interventies en wetenschappelijke technieken
            die succesvol worden toegepast in organisaties en topsport.
          </p>
          <p className={"btn-container"}>
            <Link
              to="/traject"
              className="btn primary-lg round expand-on-hover"
            >
              Meer informatie
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Organisatieadvies
